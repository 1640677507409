import React from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
} from "react-router-dom";

import ReactGA from "react-ga4";

import "./index.css";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Router from "@config/router";
import reportWebVitals from "./reportWebVitals";

ReactGA.initialize([
  {
    trackingId: "G-87EVGWB8CB",
  },
]);

const router = Router()

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Header />
    <RouterProvider router={router} />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
