import { ipmt, pmt, ppmt } from "financial";
// @ts-ignore
import { memoizeDecorator } from "memoize";

import Credit from "./Credit.interface";

class Annuity implements Credit {
  Ppmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return -1 * ppmt(rate / 100 / 12, period, totalPeriod, presentValue);
  }
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return -1 * ppmt(rate / 100 / 12, period, totalPeriod, presentValue);
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculateIpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return -1 * ipmt(rate / 100 / 12, period, totalPeriod, presentValue);
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number = 0
  ): number {
    return -1 * pmt(rate / 100 / 12, totalPeriod, presentValue);
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  fv(rate: number, totalPeriod: number, presentValue: number): number {
    let fv = 0;
    for (let i = 1; i <= totalPeriod; i++) {
      fv += this.calculatePmt(rate, totalPeriod, presentValue, i);
    }

    return fv;
  }
}

export default Annuity;
