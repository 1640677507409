import HouseOwnership from "@pages/HouseOwnership";
import Main from "@pages/Main";

export const routes = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/kpr",
    element: <HouseOwnership />,
  },
];
