import Annuity from "./Annuity";
import Credit from "./Credit.interface";
import Effective from "./Effective";
import Flat from "./Flat";

enum PeriodType {
  Monthly,
  Quarterly,
  Annualy,
}

export enum CreditType {
  Annuity = "Annuity",
  Effective = "Effective",
  Flat = "Flat",
}

export interface PaymentTable {
  principal: number;
  interest: number;
  total: number;
}

class Simulation {
  private _presentValue: number = 0;
  private _rate: number = 6;
  private _totalPeriod: number = 30;
  private _strategy: Credit;

  public get totalPeriod(): number {
    return this._totalPeriod;
  }
  public set totalPeriod(value: number) {
    this._totalPeriod = value;
  }
  public get rate(): number {
    return this._rate;
  }
  public set rate(value: number) {
    this._rate = value;
  }
  public get presentValue(): number {
    return this._presentValue;
  }
  public set presentValue(value: number) {
    this._presentValue = value;
  }

  constructor(creditType: CreditType) {
    if (creditType === CreditType.Annuity) {
      this._strategy = new Annuity();
    } else if (creditType === CreditType.Effective) {
      this._strategy = new Effective();
    } else if (creditType === CreditType.Flat) {
      this._strategy = new Flat();
    } else {
      throw new Error("There is no suitable Credit Type");
    }
  }

  calculatePmt(period: number = 1): number {
    return Number(
      this._strategy
        .calculatePmt(this._rate, this._totalPeriod, this._presentValue, period)
        .toFixed(2)
    );
  }

  calculateIpmt(period: number = 1): number {
    return Number(
      this._strategy
        .calculateIpmt(
          this._rate,
          this._totalPeriod,
          this._presentValue,
          period
        )
        .toFixed(2)
    );
  }

  calculatePpmt(period: number = 1): number {
    return Number(
      this._strategy
        .calculatePpmt(
          this._rate,
          this._totalPeriod,
          this._presentValue,
          period
        )
        .toFixed(2)
    );
  }
  calculateFv() {
    return Number(
      this._strategy
        .fv(this._rate, this._totalPeriod, this._presentValue)
        .toFixed(2)
    );
  }
  generatePaymentTable() {
    const paymentTable: PaymentTable[] = [];

    for (let i = 1; i <= this._totalPeriod; i++) {
      paymentTable.push({
        principal: this.calculatePpmt(i),
        interest: this.calculateIpmt(i),
        total: this.calculatePmt(i),
      });
    }

    return paymentTable;
  }
}

export default Simulation;
