import Credit from "./Credit.interface";

// @ts-ignore
import { memoizeDecorator } from "memoize";

class Effective implements Credit {
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    const principal =
      presentValue - (presentValue / totalPeriod) * (period - 1);
    return (principal * rate) / 12 / 100;
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculateIpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return (
      this.calculatePmt(rate, totalPeriod, presentValue, period) -
      this.calculatePpmt(rate, totalPeriod, presentValue, period)
    );
  }
  
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number = 0
  ): number {
    const principal =
      presentValue - (presentValue / totalPeriod) * (period - 1);
    return presentValue / totalPeriod + (principal * rate) / 12 / 100;
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  fv(rate: number, totalPeriod: number, presentValue: number): number {
    let fv = 0;
    for (let i = 1; i <= totalPeriod; i++) {
      fv += this.calculatePmt(rate, totalPeriod, presentValue, i);
    }

    return fv;
  }
}

export default Effective;
