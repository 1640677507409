import { fv } from "financial";
// @ts-ignore
import { memoizeDecorator } from "memoize";

import Credit from "./Credit.interface";

class Flat implements Credit {
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return presentValue / totalPeriod;
  }
  
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculateIpmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number
  ): number {
    return (
      this.calculatePmt(rate, totalPeriod, presentValue, period) -
      this.calculatePpmt(rate, totalPeriod, presentValue, period)
    );
  }
  
  @memoizeDecorator({ cacheKey: JSON.stringify })
  calculatePmt(
    rate: number,
    totalPeriod: number,
    presentValue: number,
    period: number = 0
  ): number {
    const total = this.fv(rate, totalPeriod, presentValue);

    return total / totalPeriod;
  }

  @memoizeDecorator({ cacheKey: JSON.stringify })
  fv(rate: number, totalPeriod: number, presentValue: number): number {
    return -1 * fv(rate / 100, totalPeriod / 12, 0, presentValue);
  }
}

export default Flat;
