import Simulation, { CreditType, PaymentTable } from "@core/Credit/Simulation";
import React, { useState } from "react";
import Table from "./Table";

function HouseOwnership() {
  const simulation = new Simulation(CreditType.Annuity);
  const [presentValue, setPresentValue] = useState("10000");
  const [rate, setRate] = useState(6);
  const [totalPeriod, setTotalPeriod] = useState(360);
  const [payment, setPayment] = useState<PaymentTable[]>([]);

  const addCommas = (num: number): string =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num: string): number =>
    Number(num.toString().replace(/[^0-9]/g, ""));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresentValue(addCommas(removeNonNumeric(event.target.value)));
  };
  const rateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRate(removeNonNumeric(event.target.value));
  };
  const periodHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalPeriod(removeNonNumeric(event.target.value));
  };

  function printTable() {
    simulation.presentValue = removeNonNumeric(presentValue);
    simulation.rate = rate;
    simulation.totalPeriod = totalPeriod;
    setPayment(simulation.generatePaymentTable())
  }

  return (
    <div className="flex justify-center py-16 bg-gray-500">
      <div className="w-full xl:max-w-6xl md:max-w-sm">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-present-value"
              >
                Pinjaman
              </label>
              <div className="flex mb-4">
                <div
                  id="dropdown-currency-button"
                  data-dropdown-toggle="dropdown-currency"
                  className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-200 rounded-s "
                >
                  IDR
                </div>

                <label
                  htmlFor="currency-input"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Your Email
                </label>
                <div className="relative w-full">
                  <input
                    type="text"
                    id="currency-input"
                    value={presentValue}
                    onChange={handleChange}
                    className="block p-3 w-full z-20 text-sm text-gray-900 bg-gray-200 rounded-e border-s-gray-50   focus:outline-none"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-present-rate"
              >
                Bunga
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none "
                id="grid-present-rate"
                value={rate}
                onChange={rateHandleChange}
                type="text"
                placeholder="Bunga"
              />
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-period"
              >
                Tenor
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none "
                id="grid-period"
                value={totalPeriod}
                onChange={periodHandleChange}
                type="text"
              />
            </div>
          </div>
          <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
            <button
              onClick={() => printTable()}
            >
              Generate
            </button>
          </div>
          <Table data={payment}></Table>
        </div>
        <p className="text-center text-gray-500 text-xs">
          ©2020 Acme Corp. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default HouseOwnership;
