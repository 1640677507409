import { PaymentTable } from "@core/Credit/Simulation";

function Table({ data }: { data: PaymentTable[] }) {
  if (!data.length) {
    return <p>No Data</p>
  } else
    return (
      <table className="table-auto">
        <thead>
          <tr>
            {Object.keys(data[0]).map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr>
              <td>{item.principal}</td>
              <td>{item.interest}</td>
              <td>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
}

export default Table;
